@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

html,
body {
  overflow-x: hidden;
}

/* For WebKit-based browsers (e.g., Chrome, Safari)
::-webkit-scrollbar {
  width: 10px;} Adjust the width of the scrollbar */


::-webkit-scrollbar-track {
  background: #f1f1f1; /* Set the background color of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background: #888; /* Set the color of the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Set the color of the scrollbar thumb on hover */
}
